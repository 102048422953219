require("./node_modules/adm-dtp/dist/ADM-dateTimePicker.min.js");
require("angular-translate");
require("./node_modules/angular-i18n/angular-locale_es-es");
require("./node_modules/angular-drag-and-drop-lists/angular-drag-and-drop-lists.min.js")
require("./powr/static/js/lib/inspinia.js");
require("dotenv").config({ path: '.env' })

if (process.env.API_URL) {
  global.API_URL = process.env.API_URL;
} else {
  global.API_URL = "";
}

if (process.env.GATEKEEPER_CLIENT_ID && process.env.GATEKEEPER_CLIENT_ID !== "undefined" && process.env.GATEKEEPER_URL && process.env.GATEKEEPER_URL !== "undefined") {
  global.GATEKEEPER_CLIENT_ID = process.env.GATEKEEPER_CLIENT_ID;
  global.GATEKEEPER_URL = process.env.GATEKEEPER_URL;
  global.LOGIN_HTML_TEMPLATE = require("./users/templates/login-oauth.html");
} else {
  global.GATEKEEPER_CLIENT_ID = "";
  global.GATEKEEPER_URL = "";
  global.LOGIN_HTML_TEMPLATE = require("./users/templates/login-jwt.html");
}

import package_data from "./package.json";

import angular from "angular";
import sanitize from "angular-sanitize";
import ngRoute from "angular-route";
import ngCookie from "angular-cookies";
import ngMessages from "angular-messages";
import $ from "jquery";

global.$ = $

// Modules
import { agreements } from "./agreements/js/agreements";
import { banks } from "./banks/js/banks";
import { brands } from "./brands/js/brands";
import { calendars } from "./calendars/js/calendars";
import { contracts } from "./contracts/js/contracts";
import { comments } from "./comments/js/comments";
import { companies } from "./companies/js/companies"
import { complaints } from "./complaints/js/complaints";
import { customers } from "./customers/js/customers";
import { fees } from "./fees/js/fees";
import { files } from "./files/js/files";
import { invoices } from "./invoices/js/invoices";
import { issues } from "./issues/js/issues";
import { labels } from "./labels/js/labels";
import { notifications } from "./notifications/js/notifications";
import { masters } from "./masters/js/masters";
import { products } from "./products/js/products";
import { providers } from "./providers/js/providers";
import { programming_units } from "./programming_units/js/programming_units";
import { reports } from "./reports/js/reports";
import { settings } from "./custom_settings/js/settings";
import { supplies } from "./supplies/js/supplies";
import { tasks } from "./tasks/js/tasks";
import { taxes } from "./taxes/js/taxes";
import { uploader } from "./uploader/js/uploader";
import { meta } from "./meta/js/meta";

import { stringUtils } from "./powr/static/js/services/stringUtils";
import { LoginCtrl } from "./users/js/controllers/LoginCtrl";
import { LoginOauthCtrl } from "./users/js/controllers/LoginOauthCtrl";
import { PermissionsCtrl } from "./users/js/controllers/PermissionsCtrl";
import { ChangePwdCtrl } from "./users/js/controllers/ChangePwdCtrl";
import { UserInfo } from "./users/js/services/UserInfo";
import { userAccount } from "./powr/static/js/directives/userAccount";
import { AlertCtrl } from "./powr/static/js/controllers/AlertCtrl";
import { Alert } from "./powr/static/js/services/alertBar";
import { filtersUtils } from "./powr/static/js/services/filtersUtils";
import { addressUtils } from "./powr/static/js/services/addressUtils";
import { DateUtils } from "./powr/static/js/services/dateutils";
import { markdown } from "./powr/static/js/filters/markdown";
import { cups } from "./powr/static/js/directives/cups_validator";
import { vatid } from "./powr/static/js/directives/vat_id_validator";
import { NotificationsCtrl } from "./powr/static/js/controllers/NotificationsCtrl";
import { dataFactory } from "./powr/static/js/services/typeahead";
import { selectApi } from "./powr/static/js/directives/selectApi";
import { APISelectService } from "./powr/static/js/services/apiSelect";
import { exportUtils } from "./powr/static/js/services/exportUtils";


// Directives
import { restListView } from "./powr/static/js/directives/restListView";
import {
  inject,
  listView,
  listViewConfigProvider,
} from "./powr/static/js/directives/listView";
import { mainNavigation } from "./powr/static/js/directives/mainNavigation";
import { powerData } from "./powr/static/js/directives/powerData";
import { states } from "./powr/static/js/directives/states";
import {
  replaceComma,
  addressData,
} from "./powr/static/js/directives/addressData";
import { accountNumber } from "./powr/static/js/directives/accountNumber";
import { wizard, wizardPage } from "./powr/static/js/directives/wizard";
import { suppliesTypes } from "./powr/static/js/directives/suppliesTypes";
import { energyPeriodsData } from "./powr/static/js/directives/energyPeriodsData";
import { typeaheadKf } from "./powr/static/js/directives/typeaheadDirective";
import { apiDownload } from "./powr/static/js/directives/apiDownload";
import { toggleSwitch } from "./powr/static/js/directives/toggleSwitch";

// Services
import { RatesService } from "./powr/static/js/services/RatesService";
import { PricesService } from "./powr/static/js/services/PricesService";
import { notificationSound } from "./powr/static/js/services/notificationSound";

// Filters
import {
  diacriticOrderFilter,
  orderObjectBy,
} from "./powr/static/js/filters/diacriticOrderFilter";
import { zeroPadding } from "./powr/static/js/filters/zeroPadding";

const app = angular.module("powr", [
  ngRoute,
  ngCookie,
  ngMessages,
  sanitize,
  "ADM-dateTimePicker",
  "agreements",
  "banks",
  "brands",
  "calendars",
  "comments",
  "companies",
  "complaints",
  "contracts",
  "customers",
  "fees",
  "files",
  "invoices",
  "issues",
  "labels",
  "masters",
  "notifications",
  "pascalprecht.translate",
  "products",
  "programming_units",
  "providers",
  "reports",
  "settings",
  "supplies",
  "tasks",
  "taxes",
  "uploader",
  "ui.bootstrap",
  "dndLists",
  "meta"
]);

app.factory("authInterceptor", [
  "$cookies",
  "$window",
  function ($cookies, $window) {
    const token = $cookies.get("token");
    const token_type = $cookies.get("token_type");

    if (!token && window.location.pathname != "/login/") {

      if(!$window.location.pathname.startsWith('/login')){
          let data = $window.location.href;
          let encoded_url = encodeURI(data);

          $window.location.href = '/login/?next=' + encoded_url
      }

    } else if (token && $window.location.pathname == "/login/") {

      let params = (new URL(document.location)).searchParams;
      let next = params.get("next");

      if(next){
        let decoded_url = decodeURI(next)
  
        $window.location.href = decoded_url
      }else{
        $window.location.href = "/";
      }
    }

    return {
      request: function (config) {
        if (token) {
          config.headers["Authorization"] = `${token_type} ${token}`;
        }
        return config;
      },
    };
  },
]);

app.config([
  "$httpProvider",
  "$routeProvider",
  "$locationProvider",
  "$translateProvider",
  "notificationsConfigProvider",
  "notificationCenterConfigProvider",
  "ADMdtpProvider",
  function (
    $httpProvider,
    $routeProvider,
    $locationProvider,
    $translateProvider,
    notificationsConfigProvider,
    notificationCenterConfigProvider,
    ADMdtpProvider
  ) {
    ADMdtpProvider.setOptions({
      calType: "gregorian",
      dtpType: "date",
      format: "YYYY-MM-DD",
      autoClose: true,
      multiple: false,
      freezeInput: true,
    });

    $httpProvider.defaults.xsrfCookieName = "csrftoken";
    $httpProvider.defaults.xsrfHeaderName = "X-CSRFToken";
    $httpProvider.interceptors.push("authInterceptor");
    $httpProvider.useApplyAsync(true);

    var lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;
    if (lang.indexOf("-") !== -1) lang = lang.split("-")[0];

    if (lang.indexOf("_") !== -1) lang = lang.split("_")[0];

    $locationProvider.html5Mode({
      enabled: true,
    });

    $translateProvider.preferredLanguage(lang);
    $translateProvider.translations(
      "es",
      require("./powr/static/i18n/es.json")
    );
    $translateProvider.translations(
      "en",
      require("./powr/static/i18n/en.json")
    );
    $translateProvider.useSanitizeValueStrategy("escape");

    // Configuring Notification Center
    if (API_URL.split(':')[0] !== "https") {
      // if no port is specified manually, use the protocol default
      notificationCenterConfigProvider.SOCKET_PORT = 8080;
    } else {
      notificationCenterConfigProvider.SOCKET_PORT = 443;
    }

    notificationCenterConfigProvider.SOCKET_ADDR = API_URL;
    notificationsConfigProvider.NOTIFICATION_NAMES = {
      BG_TASKS_STARTS: function (notification) {
        return notification.data.name;
      },
      BG_TASK_ENDS: function (notification) {
        return notification.data.name;
      },
      DOWNLOAD_FILE: function (notification) {
        return notification.data.name;
      },
      BG_TASK_UNPAID_TO_PAID: function (notification) {
        return notification.data.name;
      },
      BG_TASK_INVOICING_FAIL: function (notification) {
        return notification.data.name;
      },
      BG_TASK_REMITTANCE_FINISHED: function (notification) {
        return notification.data.name;
      },
      BG_TASK_FILE_PROCESSED: function (notification) {
        return notification.data.name;
      },
    };

    $routeProvider
      .when("/", {
        redirectTo: "/contracts/",
      })
      .when("/login/", {
        templateUrl: LOGIN_HTML_TEMPLATE,
      })
      .when("/agreements/", {
        templateUrl: require("./agreements/templates/agreements-list.html"),
      })
      .when("/agreements/create", {
        templateUrl: require("./agreements/templates/agreements-create.html"),
      })
      .when("/agreements/fees/", {
        templateUrl: require("./fees/templates/fees.html"),
      })
      .when("/agreements/:code", {
        templateUrl: require("./agreements/templates/agreements-detail.html"),
      })
      .when("/banks/:id", {
        templateUrl: require("./banks/templates/banks-detail.html"),
      })
      .when("/banks/", {
        templateUrl: require("./banks/templates/banks-list.html"),
      })
      .when("/company/", {
        templateUrl: require("./custom_settings/templates/settings-list.html"),
      })
      .when("/complaints/", {
        templateUrl: require("./complaints/templates/complaints.html"),
      })
      .when("/complaints/atr", {
        templateUrl: require("./complaints/templates/create-atr-complaint.html"),
      })
      .when("/complaints/create", {
        templateUrl: require("./complaints/templates/create-complaint.html"),
      })
      .when("/complaints/:id", {
        templateUrl: require("./complaints/templates/complaint-detail.html"),
      })
      .when("/contracts/", {
        templateUrl: require("./contracts/templates/contracts-list.html"),
        reloadOnSearch: false,
      })
      .when("/contracts/create", {
        templateUrl: require("./contracts/templates/draft-creation.html"),
      })
      .when("/contracts/:id", {
        templateUrl: require("./contracts/templates/contracts-detail.html"),
        controller: "ContractsDetailTabsCtrl",
        resolve: {
          agreements: function ($http) {
            return $http.get(API_URL + "/api/1.0/masters/agreements/").then(
              function (data) {
                return data.data;
              },
              function () {
                return [{ code: "Error" }];
              }
            );
          },
        },
      })
      .when("/contracts/:id/process/duplicate-contract/", {
        templateUrl: require("./contracts/templates/duplicate-contract.html"),
      })
      .when("/contracts/:id/defer-invoices/", {
        templateUrl: require("./contracts/templates/create-defer-invoices.html"),
      })
      .when("/supplies/", {
        templateUrl: require("./supplies/templates/supplies.html"),
      })
      .when("/supplies/sc", {
        templateUrl: require("./supplies/templates/secondary-concentrator.html"),
      })
      .when("/supplies/sips", {
        templateUrl: require("./supplies/templates/sips.html"),
      })
      .when("/supplies/calendars", {
        templateUrl: require("./calendars/templates/calendars.html"),
      })
      .when("/supplies/:cups", {
        templateUrl: require("./supplies/templates/supplies-detail.html"),
      })
      .when("/programming_units/", {
        templateUrl: require("./programming_units/templates/programming_units_list.html"),
      })
      .when("/programming_units/:id", {
        templateUrl: require("./programming_units/templates/programming_units_detail.html"),
      })
      .when("/customers/:id", {
        templateUrl: require("./customers/templates/customers-detail.html"),
      })
      .when("/customers/", {
        templateUrl: require("./customers/templates/customers.html"),
      })
      .when("/invoices/", {
        templateUrl: require("./invoices/templates/invoices-list.html"),
      })
      .when("/invoices/series/", {
        templateUrl: require("./invoices/templates/invoices-series-list.html"),
      })
      .when("/invoices/auto", {
        templateUrl: require("./invoices/templates/invoices-auto.html"),
      })
      .when("/invoices/create", {
        templateUrl: require("./invoices/templates/invoices-create.html"),
      })
      .when("/invoices/:id", {
        templateUrl: require("./invoices/templates/invoices-detail.html"),
      })
      .when("/invoices/:id/add-payment/", {
        templateUrl: require("./invoices/templates/add-payment.html"),
      })
      .when("/invoices/:id/add-receipt/", {
        templateUrl: require("./invoices/templates/add-receipt.html"),
      })
      .when("/issues/", {
        templateUrl: require("./issues/templates/issues-list.html"),
      })
      .when("/issues/:id", {
        templateUrl: require("./issues/templates/issue-detail.html"),
      })
      .when("/products/", {
        templateUrl: require("./products/templates/products-list.html"),
      })
      .when("/products/rates/", {
        templateUrl: require("./products/templates/rates-list.html"),
      })
      .when("/products/:code", {
        templateUrl: require("./products/templates/products-detail.html"),
      })
      .when("/products/rates/:code", {
        templateUrl: require("./products/templates/rates-detail.html"),
      })
      .when("/providers/", {
        templateUrl: require("./providers/templates/providers.html"),
      })
      .when("/providers/invoices/", {
        templateUrl: require("./providers/templates/providers-invoices.html"),
      })
      .when("/providers/xml-sync/", {
        templateUrl: require("./providers/templates/provider-invoices-sync.html"),
      })
      .when("/providers/invoices/create/", {
        templateUrl: require("./providers/templates/providers-invoices-create.html"),
      })
      .when("/providers/:id", {
        templateUrl: require("./providers/templates/providers-detail.html"),
      })
      .when("/providers/invoices/:id", {
        templateUrl: require("./providers/templates/provider-invoice-detail.html"),
      })
      .when("/receipts/", {
        templateUrl: require("./banks/templates/receipts-list.html"),
      })
      .when("/remittances/", {
        templateUrl: require("./banks/templates/remittances.html"),
      })
      .when("/remittances/new/", {
        templateUrl: require("./banks/templates/new-remittance.html"),
      })
      .when("/remittances/receipts/", {
        templateUrl: require("./banks/templates/new-receipts-remittance.html"),
      })
      .when("/remittances/:id", {
        templateUrl: require("./banks/templates/remittance-detail.html"),
      })
      .when("/reports/", {
        templateUrl: require("./reports/templates/reports.html"),
      })
      .when("/settings/", {
        templateUrl: require("./custom_settings/templates/settings-config.html"),
      })
      .when("/tasks/", {
        templateUrl: require("./tasks/templates/tasks.html"),
      })
      .when("/taxes/", {
        templateUrl: require("./taxes/templates/taxes-list.html"),
      })
      .when("/taxes/:code", {
        templateUrl: require("./taxes/templates/taxes-detail.html"),
      })
      .when("/uploads/", {
        templateUrl: require("./uploader/templates/uploader.html"),
      })
      .when("/invoices/series/", {
        templateUrl: require("/invoices/templates/invoices-series-list.html"),
      })
      .when("/notifications/", {
        templateUrl: require("/notifications/templates/notifications.html"),
      })
      .when("/user/", {
        templateUrl: require("/users/templates/change-password.html"),
      })
      .when("/queues/", {
        templateUrl: require("./meta/templates/queues.html"),
      });
  },
]);

app.controller("globals", function ($rootScope, $location) {
  $rootScope.API_URL = API_URL;
  $rootScope.GATEKEEPER_URL = GATEKEEPER_URL;
  $rootScope.GATEKEEPER_CLIENT_ID = GATEKEEPER_CLIENT_ID;
  $rootScope.VERSION = package_data.version;
  $rootScope.currentPath = function() {
    return $location.path();
  }
});

app.controller("LoginCtrl", LoginCtrl);
app.controller("LoginOauthCtrl", LoginOauthCtrl);
app.controller("PermissionsCtrl", PermissionsCtrl);
app.controller("ChangePwdCtrl", ChangePwdCtrl);
app.controller("AlertCtrl", AlertCtrl);
app.controller("NotificationsCtrl", NotificationsCtrl);
app.service("UserInfo", UserInfo);
app.service("Alert", Alert);
app.service("addressUtils", addressUtils);
app.provider("listViewConfig", listViewConfigProvider);
app.directive("restListView", restListView);
app.directive("listView", listView);
app.directive("inject", inject);
app.directive("mainNavigation", mainNavigation);
app.directive("userAccount", userAccount);
app.directive("powerData", powerData);
app.directive("states", states);
app.directive("addressData", addressData);
app.directive("replaceComma", replaceComma);
app.directive("accountNumber", accountNumber);
app.directive("wizard", wizard);
app.directive("wizardPage", wizardPage);
app.directive("suppliesTypes", suppliesTypes);
app.directive("energyPeriodsData", energyPeriodsData);
app.directive("typeaheadKf", typeaheadKf);
app.directive("apiDownload", apiDownload);
app.directive("toggleSwitch", toggleSwitch);
app.service("filtersUtils", filtersUtils);
app.service("DateUtils", DateUtils);
app.service("stringUtils", stringUtils);
app.service("RatesService", RatesService);
app.service("PricesService", PricesService);
app.service("notificationSound", notificationSound);
app.service("exportUtils", exportUtils);
app.filter("diacriticOrderFilter", diacriticOrderFilter);
app.filter("orderObjectBy", orderObjectBy);
app.filter("markdown", markdown);
app.directive("cups", cups);
app.directive("vatid", vatid);
app.filter("zeroPadding", zeroPadding);
app.factory("dataFactory", dataFactory);
app.directive("selectApi", selectApi);
app.service("APISelectService", APISelectService);

app.run(function (UserInfo, $timeout) {
  UserInfo.retrieveInfo();
});
